<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <el-card>
      <div class="vd_button_group">
        <span v-if="disabled === true">
          <el-button type="success" @click="openEdit" size="small" :disabled="!btn.edit">编辑</el-button>
        </span>
        <span v-if="disabled === false">
          <el-button type="primary" @click="submit('roleForm')" size="small">保存</el-button>
          <el-button @click="closeEdit" size="small">取消</el-button>
        </span>
      </div>
      <el-form ref="roleForm" :model="roleForm" :rules="rules" :disabled="disabled" label-width="120px" size="mini">
        <el-row>
          <el-col :md="8">
            <el-form-item label="角色名称" prop="role_name">
              <el-input v-model="roleForm.role_name" maxlength="10" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="vd_button"></div>
      <el-row class="vg_mb_8">
        <el-col :md="12">
          <el-input placeholder="输入关键字进行过滤" v-model="filterText" size="mini"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="12">
          <el-tree
            :data="roleForm.role_perm_id_list"
            ref="tree"
            show-checkbox
            node-key="perm_id"
            :props="roleForm.defaultProps"
            :filter-node-method="filterNode"
            @check-change="getCheckedData"
            @check="getNodeIds"
            v-if="treeDeath"
          >
          </el-tree>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { roleAPI } from '@api/modules/role';
import { permAPI } from '@api/modules/perm';
export default {
  name: 'RoleEdit',
  data() {
    return {
      disabled: true,
      roleForm: {
        role_id: Number,
        role_name: '',
        role_perm_id_list: [],
        defaultProps: {
          children: 'perm_child_list',
          label: data => data.perm_id + ' ' + data.perm_name,
          disabled: this.disabledFn
        }
      },
      checkedTreeArr: [],
      actionsIds: [],
      actionsIdsGroup: [],
      permIdArr: [],
      rules: {
        role_name: [{ required: true, trigger: 'blur' }]
      },
      filterText: '',
      btn: {},
      treeDeath: false,
      userList: [],
      role_name: '',
      loadingFlag: true
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.initData();
    this.treeDeath = true;
  },
  methods: {
    //启用编辑
    openEdit() {
      this.disabled = false;
      this.disabledFn();
    },
    // tree 禁止
    disabledFn() {
      if (this.disabled === true) {
        return true;
      } else {
        return false;
      }
    },
    //取消编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.disabled = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    initData() {
      if (this.$route.query.form_id) {
        this.loadingFlag = true;
        this.getRoleInfo();
        this.disabledFn();
        this.getRoles();
        setTimeout(() => {
          this.getPermTree();
        }, 100);
      }
    },
    // 获取角色信息
    getRoles() {
      get(roleAPI.getAllRoles)
        .then(res => {
          if (res.data.code === 0) {
            for (let i of res.data.data.list) {
              this.userList.push(i.role_name);
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取角色信息
    getRoleInfo() {
      this.roleForm.role_id = this.$route.query.form_id;
      get(roleAPI.getRoleById, { role_id: this.roleForm.role_id })
        .then(res => {
          if (res.data.code === 0) {
            this.roleForm = res.data.data.form;
            this.role_name = res.data.data.form.role_name;
            this.btn = res.data.data.btn;
            if (this.roleForm.role_perm_ids !== null) {
              let permIdStr = this.roleForm.role_perm_ids.split(',');
              permIdStr.forEach(item => {
                this.permIdArr.push(+item);
              });
              this.checkedTreeArr = JSON.parse(JSON.stringify(this.permIdArr));
              this.$refs.tree.setCheckedKeys(this.permIdArr, true, true);
            }
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
            // let that = this
            // setTimeout(function() {
            //   that.permIdArr.forEach(value => {
            //     console.log('value',value)
            //     // that.$refs.tree.setCheckedKeys(value,true,true)
            //     // let node = that.$refs.tree.getNode(value);
            //     // console.log('node',node)
            //     // console.log('isLeaf',node.isLeaf)
            //     // if (node.isLeaf) {
            //     //     that.$refs.tree.setChecked(value,true,true);
            //     // }
            //   });
            // }, 100);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取权限树状
    getPermTree() {
      get(permAPI.getAllPerms)
        .then(res => {
          if (res.data.code === 0) {
            this.roleForm.role_perm_id_list = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 树节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.perm_name.indexOf(value) !== -1;
    },
    // 获取角色权限数据
    getNodeIds(val, checkedArr) {
      checkedArr.checkedNodes.map(item => {
        this.checkedTreeArr.push(item.perm_id);
      });
      checkedArr.halfCheckedNodes.map(item => {
        this.checkedTreeArr.push(item.perm_id);
      });
      this.checkedTreeArr = checkedArr.checkedKeys.concat(checkedArr.halfCheckedKeys);
    },
    getCheckedData(val) {
      this.actionsIds = [];
      this.actionsIdsGroup = this.$refs.tree.getCheckedNodes(false, true);
      this.actionsIdsGroup.map(item => {
        this.actionsIds.push(item.perm_id);
      });
    },

    //提交表单
    submit(formName) {
      let f = 0;
      for (let i of this.userList) {
        if (this.roleForm.role_name === i) {
          f++;
        }
      }
      if ((this.role_name == this.roleForm.role_name && f == 1) || f == 0) {
        this.$confirm('确认保存？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$refs[formName].validate(valid => {
              if (valid) {
                this.saveInfo();
              } else {
                return false;
              }
            });
          })
          .catch(() => {});
      } else {
        return this.$message.warning('该角色名称已存在');
      }
    },
    // 保存
    saveInfo() {
      let roleBody = {};
      roleBody.role_id = this.roleForm.role_id;
      roleBody.stff_id = this.roleForm.stff_id; //更新人id
      roleBody.role_name = this.roleForm.role_name;
      if (this.checkedTreeArr.length === 0) {
        roleBody.role_perm_id_list = this.actionsIds;
      } else {
        roleBody.role_perm_id_list = this.checkedTreeArr;
      }
      roleBody.role_perm_id_list = [...new Set(roleBody.role_perm_id_list)];
      if (roleBody.role_perm_id_list.length > 0) {
        post(roleAPI.editRole, roleBody)
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '保存成功！'
              });
              this.disabled = true;
              this.disabledFn();
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      } else {
        this.$message({
          type: 'error',
          message: '至少选择一条'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.vd_title {
  color: $color-deep-red;
}
</style>
